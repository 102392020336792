import { useEffect } from "react";

function FunctionRunner(props) {
	useEffect(() => {
		props.function();
	}, []); // eslint-disable-line

	return props.children;
}

export default FunctionRunner;
