import React from "react";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ModuleBase from "./ModuleBase";
import utils from "../../utils/utils";
import { Button, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { Box } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";

function QrCodeModule(props) {
	const data = { ...props.data };

	const setQrCode = (val) => {
		data.qrCode = val;
		props.updateData(data);
	};

	return (
		<ModuleBase
			title="Código QR"
			onDelete={props.onDelete}
			updateData={props.updateData}
			icon={QrCodeModuleInfo.icon}
			data={data}
		>
			<Typography sx={{ mb: 1 }} fontWeight="light">
				Código QR do checkpoint
			</Typography>
			<Box sx={{ mb: 1 }}>
				<QRCodeSVG value={data.qrCode} />
			</Box>
			<Button
				variant="outlined"
				color="secondary"
				onClick={() => setQrCode(utils.randomStr(12))}
			>
				Gerar novo
			</Button>
			<Button
				variant="outlined"
				color="secondary"
				sx={{ ml: 2 }}
				onClick={() => utils.downloadQr(data.qrCode, "Checkpoint")}
				endIcon={<DownloadIcon />}
			>
				Transferir
			</Button>
		</ModuleBase>
	);
}

const QrCodeModuleInfo = {
	id: "qrcode",
	name: "Código QR",
	icon: <QrCodeIcon />,
	description:
		"Com este módulo, o utilizador terá que fazer scan de um código QR para prosseguir",
};

const getDefaultQrCodeStep = () => {
	return {
		title: "",
		picture: null,
		description: "",
		stepType: "qrcode",

		qrCode: utils.randomStr(12),
	};
};

const validateQrCodeStep = (step) => {
	if (step.title === "") {
		return { valid: false, reason: "O título não pode ser vazio." };
	}

	return { valid: true };
};

export {
	QrCodeModule,
	QrCodeModuleInfo,
	getDefaultQrCodeStep,
	validateQrCodeStep,
};
