import React, { useState } from 'react'
import { Button, Container, Link, Paper, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import logo from "../img/logo.png";
import colors from '../utils/colors';
import axios from 'axios';
import config from '../config';
import { errorHandler } from '../utils/axios-utils';
import { useWithLoading } from '../hooks/useWithLoading';

function ForgotPassword() {
    const withLoading = useWithLoading();

    const [email, setEmail] = useState("");

    const register = () => {
        if (email === "") {
            return toast.error("Deves preencher todos os campos.");
        }

        withLoading(axios.post(`${config.API_BASE_URL}/users/forgot-password`, {
            email
        })
            .then(() => {
                toast.success("Email enviado com sucesso! Verifica a tua caixa de entrada.");
            })
            .catch(errorHandler((code) => {
                switch (code) {
                    case 404:
                        toast.error("Email inválido");
                        return true;
                    case 429:
                        toast.warn("Já foi enviado um email recentemente para esta conta.");
                        return true;
                    default:
                        return false;
                }
            })));
    };

    return (
        <Container sx={{ mt: 3 }}>
            <img alt="Culturama" src={logo} height={150} style={{ margin: "0 auto", display: "block" }} />
            <Paper sx={{ mt: 1, p: 3 }}>
                <Typography variant="h2" sx={{ fontSize: 30, textAlign: 'center', color: colors.primary }}>Esqueci a password</Typography>
                <TextField variant="outlined" name="email" type="email" label="Email" fullWidth sx={{ mt: 4 }} value={email} onChange={e => setEmail(e.target.value)}></TextField>
                <Button variant="contained" sx={{ m: "0 auto", mt: 2, color: "white", display: "block" }} fullWidth onClick={register}>Enviar</Button>
                <Typography sx={{ mt: 2 }}>Não tens uma conta? <Link href="/register">Regista-te aqui</Link></Typography>
            </Paper>
        </Container>
    )
}

export default ForgotPassword;