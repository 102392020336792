import { toast } from "react-toastify";

const QRCode = require("qrcode");

const randomStr = (length) => {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

const downloadQr = (value, qrName) => {
	QRCode.toDataURL(value, { width: 500, height: 500 })
		.then((dataUrl) => {
			let link = document.createElement("a");
			link.download = `${qrName}-${value}.png`;
			link.href = dataUrl;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			link.remove();
		})
		.catch((err) => {
			console.error(err);
			toast.error("Ocorreu um erro inesperado");
		});
};

const utils = { randomStr, downloadQr };

export default utils;
