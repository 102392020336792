import React, { useState } from 'react'
import { Button, Checkbox, Container, Divider, FormControlLabel, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import logo from "../img/logo.png";
import colors from '../utils/colors';
import axios from 'axios';
import config from '../config';
import { errorHandler } from '../utils/axios-utils';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useWithLoading } from '../hooks/useWithLoading';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

function Login() {
    const navigate = useNavigate();
    const withLoading = useWithLoading();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    const emailPasswordLogin = () => {
        if (email === "" || password === "") {
            return toast.error("Deves preencher todos os campos.");
        }

        withLoading(axios.post(`${config.API_BASE_URL}/auth/login`, {
            email,
            password,
            rememberMe
        })
            .then(res => {
                let token = res.data.token;
                Cookies.set("culturama-auth-token", token);
                navigate("/");
            })
            .catch(errorHandler((code) => {
                switch (code) {
                    case 401:
                        toast.error("Email ou password incorretos");
                        return true;
                    case 403:
                        toast.error("A tua conta ainda não foi verificada. Verifica o teu email.");
                        return true;
                    default:
                        return false;
                }
            })));
    };

    const googleLogin = () => {
        window.open(`${config.API_BASE_URL}/auth/oauth/google?callbackUrl=${window.location.origin}/logged-in`, "_self");
    };

    const facebookLogin = () => {
        window.open(`${config.API_BASE_URL}/auth/oauth/facebook?callbackUrl=${window.location.origin}/logged-in`, "_self");
    };

    return (
        <Container sx={{ mt: 3 }}>
            <img alt="Culturama" src={logo} height={150} style={{ margin: "0 auto", display: "block" }} />
            <Paper sx={{ mt: 1, p: 3 }}>
                <Typography variant="h2" sx={{ fontSize: 30, textAlign: 'center', color: colors.primary }}>Entrar</Typography>
                <TextField variant="outlined" name="email" type="email" label="Email" fullWidth sx={{ mt: 4 }} value={email} onChange={e => setEmail(e.target.value)}></TextField>
                <TextField variant="outlined" name="password" type="password" label="Password" fullWidth sx={{ mt: 2 }} value={password} onChange={e => setPassword(e.target.value)}></TextField>
                <FormControlLabel labelPlacement='start' sx={{ mt: 1, color: "grey" }} control={<Checkbox value={rememberMe} onChange={e => setRememberMe(e.target.checked)} />} label="Lembrar-me" />
                <Button variant="contained" sx={{ m: "0 auto", mt: 2, color: "white", display: "block" }} fullWidth onClick={emailPasswordLogin}>Entrar</Button>
                <Typography sx={{ mt: 2 }}>Não tens uma conta? <Link href="/register">Regista-te aqui</Link></Typography>
                <Typography><Link href="/forgot-password">Esqueci a minha password</Link></Typography>
                <Divider><Typography sx={{ fontWeight: 100, mt: 2, mb: 4 }}>ou</Typography></Divider>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Button onClick={googleLogin} startIcon={<GoogleIcon />} variant="outlined">Google</Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Button onClick={facebookLogin} startIcon={<FacebookIcon />} variant="outlined">Facebook</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default Login