import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "./config";
import { headers, errorHandler } from "./utils/axios-utils";
import { useWithLoading } from "./hooks/useWithLoading";
import {
	AppBar,
	Avatar,
	Box,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Cookies from "js-cookie";
import logo from "./img/logo.png";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AreYouSureDialog from "./components/AreYouSureDialog";
import EventIcon from "@mui/icons-material/Event";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import Organization from "./pages/Organization";
import EventEditor from "./pages/EventEditor";
import FunctionRunner from "./components/FunctionRunner";

function Main() {
	const withLoading = useWithLoading();
	const navigate = useNavigate();

	const [user, setUser] = useState(null);
	const [license, setLicense] = useState(null);
	const [licenseError, setLicenseError] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [logoutOpen, setLogoutOpen] = useState(false);
	const [title, setTitle] = useState("Portal");

	useEffect(() => {
		let getUser = axios
			.get(`${config.API_BASE_URL}/users/me`, headers())
			.then((res) => {
				setUser(res.data);
			})
			.catch(errorHandler());

		let getLicense = axios
			.get(`${config.API_BASE_URL}/licenses/mine`, headers())
			.then((res) => {
				setLicense(res.data);
				Cookies.set("culturama-license-id", res.data._id);
			})
			.catch(
				errorHandler((status, data) => {
					if (status === 404) {
						setLicenseError(data.code);
						return true;
					}
				})
			);

		withLoading(Promise.all([getUser, getLicense]));
	}, []); // eslint-disable-line

	const getLicenseError = () => {
		if (licenseError === "NO_ORG") {
			return (
				<Typography>
					A tua conta não pertence a nenhuma organização. Contacta{" "}
					<a
						target="_blank"
						rel="noreferrer"
						href="mailto:culturama@weproductise.com"
					>
						culturama@weproductise.com
					</a>{" "}
					para mais informações.
				</Typography>
			);
		} else if (licenseError === "NO_LICENSE") {
			return (
				<Typography>
					A tua organização não possui uma licensa válida. Contacta{" "}
					<a
						target="_blank"
						rel="noreferrer"
						href="mailto:culturama@weproductise.com"
					>
						culturama@weproductise.com
					</a>{" "}
					para mais informações.
				</Typography>
			);
		}
	};

	const getMainRouter = () => {
		return (
			<Routes>
				<Route
					path="/"
					element={
						<FunctionRunner function={() => setTitle("Painel Inicial")}>
							<Dashboard />
						</FunctionRunner>
					}
				/>

				<Route
					path="/organization"
					element={
						<FunctionRunner function={() => setTitle("Organização")}>
							<Organization license={license} />
						</FunctionRunner>
					}
				/>

				<Route
					path="/events"
					element={
						<FunctionRunner function={() => setTitle("Eventos")}>
							<Events />
						</FunctionRunner>
					}
				/>

				<Route
					path="/create-event"
					element={
						<FunctionRunner function={() => setTitle("Criar Evento")}>
							<EventEditor />
						</FunctionRunner>
					}
				/>

				<Route
					path="/event/:editId"
					element={
						<FunctionRunner function={() => setTitle("Ver/Editar Evento")}>
							<EventEditor />
						</FunctionRunner>
					}
				/>

				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		);
	};

	const getMainContent = () => {
		if (license == null) {
			return getLicenseError();
		} else {
			return getMainRouter();
		}
	};

	const logout = () => {
		Cookies.remove("culturama-auth-token");
		Cookies.remove("culturama-license-id");
		window.location.reload();
	};

	const getUserHeader = () => {
		return (
			<Box sx={{ p: 2 }}>
				<Avatar sx={{ height: 60, width: 60, m: "0 auto" }}>
					<PersonIcon />
				</Avatar>
				<Typography
					sx={{
						textOverflow: "ellipsis",
						textAlign: "center",
						overflow: "hidden",
						mt: 1,
					}}
				>
					{user.email}
				</Typography>
				<IconButton
					sx={{ mt: 1, ml: "auto", mr: "auto", display: "block" }}
					color="secondary"
					onClick={() => setLogoutOpen(true)}
				>
					<LogoutIcon />
				</IconButton>
			</Box>
		);
	};

	const openPage = (path) => {
		setDrawerOpen(false);
		navigate(path);
	};

	const getDrawerList = () => {
		if (license == null) return null;

		return (
			<List sx={{ p: 2 }}>
				<ListItemButton onClick={() => openPage("/")}>
					<ListItemIcon>
						<DashboardIcon />
					</ListItemIcon>
					<ListItemText>Painel</ListItemText>
				</ListItemButton>

				<ListItemButton onClick={() => openPage("/organization")}>
					<ListItemIcon>
						<CorporateFareIcon />
					</ListItemIcon>
					<ListItemText>Organização</ListItemText>
				</ListItemButton>

				<ListItemButton onClick={() => openPage("/events")}>
					<ListItemIcon>
						<EventIcon />
					</ListItemIcon>
					<ListItemText>Eventos</ListItemText>
				</ListItemButton>
			</List>
		);
	};

	if (user == null) return null;

	return (
		<Box>
			<AppBar sx={{ bgcolor: "#fff" }}>
				<Toolbar>
					<IconButton onClick={() => setDrawerOpen(true)} edge="start">
						<MenuIcon />
					</IconButton>
					<img alt="logo" src={logo} height={50} />
					<Typography fontWeight="bold" sx={{ ml: 1 }}>
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
				<Box sx={{ width: 250 }}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							p: 2,
						}}
					>
						<IconButton onClick={() => setDrawerOpen(false)}>
							<ChevronLeftIcon />
						</IconButton>
					</Box>
					{getUserHeader()}
					<Divider />
					{getDrawerList()}
				</Box>
			</Drawer>
			<Box sx={{ p: 4, mt: 8 }}>{getMainContent()}</Box>
			<AreYouSureDialog
				open={logoutOpen}
				content="Tens a certeza que queres sair?"
				onClose={() => setLogoutOpen(false)}
				onConfirm={logout}
			/>
		</Box>
	);
}

export default Main;
