import {
	Box,
	Paper,
	Container,
	Drawer,
	Fab,
	FormControlLabel,
	IconButton,
	Switch,
	TextField,
	Typography,
	Tooltip,
	Button,
	Grid,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useRef, useState } from "react";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import PhotoIcon from "@mui/icons-material/Photo";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
	getDefaultQrCodeStep,
	QrCodeModuleInfo,
	QrCodeModule,
	validateQrCodeStep,
} from "../components/modules/QrCodeModule";
import utils from "../utils/utils";
import { QRCodeSVG } from "qrcode.react";
import {
	getDefaultQuizzStep,
	QuizzModule,
	QuizzModuleInfo,
	validateQuizzStep,
} from "../components/modules/QuizzModule";
import { toast } from "react-toastify";
import { useWithLoading } from "../hooks/useWithLoading";
import axios from "axios";
import config from "../config";
import { errorHandler, headers } from "../utils/axios-utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import AreYouSureDialog from "../components/AreYouSureDialog";
import {
	getDefaultInformationStep,
	InformationModule,
	InformationModuleInfo,
	validateInformationStep
} from "../components/modules/InformationModule";

const moduleInfos = [QrCodeModuleInfo, QuizzModuleInfo, InformationModuleInfo];

function ModuleAddButton(props) {
	const [hover, setHover] = useState(false);

	return (
		<Draggable draggableId={props.moduleInfo.id} index={props.index}>
			{(provided) => (
				<Tooltip
					title={props.moduleInfo.description}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<Paper
						sx={{
							height: 80,
							width: 120,
							p: 1,
							mr: 2,
							textAlign: "center",
							bgcolor: hover && "#e1e1e1",
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							justifyContent: "center",
							transition: "0.3s",
							cursor: "pointer",
						}}
						onMouseOver={() => setHover(true)}
						onMouseOut={() => setHover(false)}
					>
						<Typography>{props.moduleInfo.name}</Typography>
						{props.moduleInfo.icon}
					</Paper>
				</Tooltip>
			)}
		</Draggable>
	);
}

function ModulesButton(props) {
	return (
		<Fab
			variant="extended"
			color="primary"
			sx={{
				position: "fixed",
				bottom: 25,
				left: "50%",
				transform: "translate(-50%, -50%)",
			}}
			onClick={props.onClick}
		>
			<ViewModuleIcon sx={{ mr: 1, color: "white" }} />
			<Typography color="white">Módulos</Typography>
		</Fab>
	);
}

function ModulesDrawer(props) {
	return (
		<Drawer anchor="bottom" open={props.open} variant="persistent">
			<Box sx={{ height: 150, ml: 1, mt: 1 }}>
				<IconButton
					sx={{ margin: "0 auto", display: "block" }}
					onClick={props.onClose}
				>
					<ExpandMoreIcon />
				</IconButton>

				<Droppable
					droppableId="event-editor-module-list"
					isDropDisabled
					direction="horizontal"
				>
					{(provided) => (
						<Box
							{...provided.droppableProps}
							sx={{ display: "flex", flexDirection: "row", pl: 2, pr: 2 }}
							ref={provided.innerRef}
						>
							{moduleInfos.map((info, index) => (
								<ModuleAddButton key={index} index={index} moduleInfo={info} />
							))}

							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</Box>
		</Drawer>
	);
}

function EventEditor() {
	const withLoading = useWithLoading();
	const navigate = useNavigate();
	const { editId } = useParams();

	const [modulesOpen, setModulesOpen] = useState(false);
	const [deleteEventOpen, setDeleteEventOpen] = useState(false);

	const [editEvent, setEditEvent] = useState(null);

	const [name, setName] = useState("");
	const [pictureFile, setPictureFile] = useState(null);
	const [description, setDescription] = useState("");
	const [location, setLocation] = useState("");
	const [permanent, setPermanent] = useState(false);
	const [datetime, setDatetime] = useState(null);
	const [checkin, setCheckin] = useState(false);
	const [checkinQrCode, setCheckinQrCode] = useState("");
	const [steps, setSteps] = useState([]);

	const pictureRef = useRef();

	useEffect(() => {
		if (editId != null) {
			withLoading(
				axios
					.get(`${config.API_BASE_URL}/events/mine/${editId}`, headers())
					.then((res) => {
						setEditEvent(res.data);
						setEventData(res.data);
					})
					.catch(errorHandler())
			);
		}
	}, [editId]); // eslint-disable-line

	const setEventData = (event) => {
		setName(event.name);
		setDescription(event.description);
		setLocation(event.location);
		setPermanent(event.permanent);
		setDatetime(event.permanent ? moment() : moment(event.datetime));
		setCheckin(event.checkin);
		if (event.checkin) setCheckinQrCode(event.checkinCode);
		setSteps(event.steps);
	};

	const submitEvent = () => {
		if (!validateEvent()) return;

		const formdata = new FormData();

		let imagesMap = {
			main: null,
			steps: [],
		};

		let images = [];
		if (pictureFile != null) {
			images.push(pictureFile);
			imagesMap["main"] = 0;
		}

		for (let i = 0; i < steps.length; i++) {
			let step = { ...steps[i] };

			if (step.pictureFile != null) {
				imagesMap["steps"].push(images.length);
				images.push(step.pictureFile);
			} else {
				imagesMap["steps"].push(null);
			}
		}

		for (let image of images) {
			formdata.append("images", image);
		}

		formdata.append("imagesMap", JSON.stringify(imagesMap));

		formdata.append("name", name);
		formdata.append("description", description);
		formdata.append("location", location);
		formdata.append("permanent", permanent);
		if (!permanent) formdata.append("datetime", datetime.valueOf());
		formdata.append("steps", JSON.stringify(steps));
		formdata.append("checkin", checkin);
		if (checkin) formdata.append("checkinCode", checkinQrCode);
		if (editEvent != null) formdata.append("picture", editEvent.picture);

		let requestHeaders = headers();
		requestHeaders.headers["Content-Type"] = "multipart/form-data";

		if (editEvent == null) {
			withLoading(
				axios
					.post(
						`${config.API_BASE_URL}/events/create`,
						formdata,
						requestHeaders
					)
					.then(() => {
						toast.success("Evento criado com sucesso!");
						navigate("/events");
					})
					.catch(errorHandler())
			);
		} else {
			withLoading(
				axios
					.post(
						`${config.API_BASE_URL}/events/edit/${editEvent._id}`,
						formdata,
						requestHeaders
					)
					.then(() => {
						toast.success("Evento atualizado com sucesso!");
					})
					.catch(errorHandler())
			);
		}
	};

	const deleteEvent = () => {
		if (editEvent == null) return;

		withLoading(
			axios
				.delete(
					`${config.API_BASE_URL}/events/delete/${editEvent._id}`,
					headers()
				)
				.then(() => {
					toast.success("Evento apagado com sucesso!");
					navigate("/events");
				})
				.catch(errorHandler())
		);
	};

	const getStepItemSx = (isDragging, itemStyle) => ({
		userSelect: "none",
		mt: 1,
		background: isDragging ? "#d4ebf2" : "#ffffff",
		borderRadius: 2,
		...itemStyle,
	});

	const getCheckinQrCode = () => {
		if (!checkin) return null;

		return (
			<Paper sx={{ mt: 1, p: 3 }}>
				<Grid container>
					<Grid item>
						<Typography sx={{ mb: 2 }}>Código QR do Check-In</Typography>
						<QRCodeSVG value={checkinQrCode} />
					</Grid>
					<Grid item sx={{ ml: 3 }}>
						<Button onClick={() => setCheckinQrCode(utils.randomStr(12))}>
							Gerar novo
						</Button>
						<br />
						<Button
							sx={{ mt: 2 }}
							onClick={() => utils.downloadQr(checkinQrCode, "CheckIn")}
							endIcon={<DownloadIcon />}
						>
							Transferir
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	};

	const deleteStep = (index) => {
		const newSteps = [...steps];
		newSteps.splice(index, 1);
		setSteps(newSteps);
	};

	const updateStep = (data, index) => {
		const newSteps = [...steps];
		newSteps[index] = data;
		setSteps(newSteps);
	};

	const getModule = (step, index) => {
		switch (step.stepType) {
			case "qrcode":
				return (
					<QrCodeModule
						data={step}
						onDelete={() => deleteStep(index)}
						updateData={(data) => updateStep(data, index)}
					/>
				);
			case "quizz":
				return (
					<QuizzModule
						data={step}
						onDelete={() => deleteStep(index)}
						updateData={(data) => updateStep(data, index)}
					/>
				);
			case "info":
				return (
					<InformationModule
						data={step}
						onDelete={() => deleteStep(index)}
						updateData={(data) => updateStep(data, index)}
					/>
				);
			default:
				return null;
		}
	};

	const onDragEnd = (result) => {
		const { source, destination, draggableId } = result;

		if (
			destination == null ||
			destination.droppableId !== "event-editor-modules"
		) {
			return;
		}

		if (source.droppableId !== "event-editor-module-list") {
			const newSteps = [...steps];
			const el = newSteps.splice(source.index, 1)[0];
			newSteps.splice(destination.index, 0, el);
			setSteps(newSteps);
			return;
		}

		let step = null;
		switch (draggableId) {
			case "qrcode":
				step = getDefaultQrCodeStep();
				break;
			case "quizz":
				step = getDefaultQuizzStep();
				break;
			case "info":
				step = getDefaultInformationStep();
				break;
			default:
				step = null;
				break;
		}

		if (step != null) {
			let newSteps = [...steps];
			newSteps.splice(destination.index, 0, step);
			setSteps(newSteps);
		}
	};

	const validateEvent = () => {
		if (name === "") {
			toast.error("O nome do evento não pode ser vazio.");
			return false;
		}

		if (location === "") {
			toast.error("A localização do evento não pode ser vazia.");
			return false;
		}

		if (!permanent && (datetime == null || datetime === "" || !datetime.isValid())) {
			toast.error("A data e hora do evento devem ser válidas");
			return false;
		}

		if (steps.length === 0) {
			toast.error("Um evento deve ter pelo menos um checkpoint");
			return false;
		}

		for (let i = 0; i < steps.length; i++) {
			const step = steps[i];

			let validationResult = null;
			switch (step.stepType) {
				case "qrcode":
					validationResult = validateQrCodeStep(step);
					break;
				case "quizz":
					validationResult = validateQuizzStep(step);
					break;
				case "info":
					validationResult = validateInformationStep(step);
					break;
				default:
					validationResult = false;
					break;
			}

			if (!validationResult.valid) {
				toast.error(`Erro no checkpoint #${i + 1}. ${validationResult.reason}`);
				return false;
			}
		}

		return true;
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Container sx={{ mb: 20 }}>
				{editEvent != null ? (
					<Button
						variant="outlined"
						color="error"
						sx={{ mb: 3 }}
						endIcon={<DeleteIcon />}
						onClick={() => setDeleteEventOpen(true)}
					>
						Apagar Evento
					</Button>
				) : null}

				<TextField
					label="Nome do Evento"
					variant="standard"
					inputProps={{ style: { fontSize: 40 } }}
					fullWidth
					autoComplete="off"
					required
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>

				{pictureFile != null ? (
					<Box maxHeight={200} maxWidth={356} sx={{ mt: 2 }}>
						<img
							alt=""
							style={{ objectFit: "cover", maxHeight: 200, width: "100%" }}
							src={URL.createObjectURL(pictureFile)}
						/>
					</Box>
				) : editEvent?.picture != null ? (
					<Box maxHeight={200} maxWidth={356} sx={{ mt: 2 }}>
						<img
							alt=""
							style={{ objectFit: "cover", maxHeight: 200, width: "100%" }}
							src={`${config.API_BASE_URL}/uploads/${editEvent.picture}`}
						/>
					</Box>
				) : null}
				<Button
					variant="outlined"
					startIcon={<PhotoIcon />}
					component="label"
					sx={{ mr: 1, mt: 2 }}
				>
					Imagem
					<input
						type="file"
						accept="image/png, image/jpg, image/jpeg"
						ref={pictureRef}
						hidden
						onChange={(e) => setPictureFile(e.target.files[0])}
					/>
				</Button>
				{pictureFile != null || editEvent?.picture != null ? (
					<Button
						variant="outlined"
						sx={{ mt: 2 }}
						startIcon={<DeleteIcon />}
						onClick={() => {
							setPictureFile(null);
							if (editEvent != null) {
								let newEvent = { ...editEvent };
								newEvent.picture = null;
								setEditEvent(newEvent);
							}
						}}
					>
						Remover Imagem
					</Button>
				) : null}

				<Typography sx={{ fontSize: 12, mt: 2, mb: 1, color: "gray" }}>
					Descrição
				</Typography>
				<ReactQuill
					theme="snow"
					value={description}
					onChange={(val) => setDescription(val)}
				/>

				<TextField
					sx={{ mt: 2 }}
					label="Localização"
					fullWidth
					name="location"
					required
					value={location}
					onChange={(e) => setLocation(e.target.value)}
				/>

				<Box>
					<FormControlLabel
						control={
							<Switch
								checked={permanent}
								onChange={(e) => setPermanent(e.target.checked)}
							/>
						}
						labelPlacement="start"
						sx={{ mt: 2 }}
						label="Evento permanente"
					/>
				</Box>

				{permanent ? null : <DateTimePicker
					label="Data & Hora"
					name="datetime"
					value={datetime}
					onChange={(v) => setDatetime(v)}
					renderInput={(params) => (
						<TextField fullWidth required sx={{ mt: 2 }} {...params} />
					)}
				/>}

				<Box>
					<FormControlLabel
						control={
							<Switch
								checked={checkin}
								onChange={(e) => {
									setCheckin(e.target.checked);
									if (checkinQrCode === "") {
										setCheckinQrCode(utils.randomStr(12));
									}
								}}
							/>
						}
						labelPlacement="start"
						sx={{ mt: 2 }}
						label="Evento com check-in"
					/>
				</Box>
				{getCheckinQrCode()}

				<Typography sx={{ mt: 3, mb: 1 }} fontWeight="light">
					Checkpoints
				</Typography>
				<Container sx={{ p: 3, bgcolor: "#efefef", borderRadius: 3 }}>
					{steps.length === 0 ? (
						<Typography sx={{ opacity: 0.6 }}>
							Arrasta para aqui os módulos do teu evento
						</Typography>
					) : null}

					<Droppable droppableId="event-editor-modules">
						{(provided) => (
							<Box
								{...provided.droppableProps}
								ref={provided.innerRef}
								sx={{ width: "100%" }}
							>
								{steps.map((step, index) => (
									<Draggable
										key={`${step.stepType}-${index}`}
										draggableId={`${step.stepType}-${index}`}
										index={index}
									>
										{(provided, snapshot) => (
											<Box
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												sx={getStepItemSx(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												{getModule(step, index)}
											</Box>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</Container>

				<Button
					sx={{ mt: 2, color: "white" }}
					variant="contained"
					endIcon={<SaveIcon />}
					onClick={submitEvent}
				>
					Guardar
				</Button>

				<ModulesButton onClick={() => setModulesOpen(true)} />
				<ModulesDrawer
					open={modulesOpen}
					onClose={() => setModulesOpen(false)}
				/>
			</Container>

			<AreYouSureDialog
				open={deleteEventOpen}
				onClose={() => setDeleteEventOpen(false)}
				content="Tens a certeza que queres apagar este evento? Esta ação é irreversível!"
				onConfirm={() => {
					deleteEvent();
					setDeleteEventOpen(false);
				}}
			/>
		</DragDropContext>
	);
}

export default EventEditor;
