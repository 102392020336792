import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import ModuleBase from "./ModuleBase";

function InformationModule(props) {
    const data = { ...props.data };

    return (
        <ModuleBase
            title="Informação"
            icon={InformationModuleInfo.icon}
            onDelete={props.onDelete}
            updateData={props.updateData}
            data={data}
        ></ModuleBase>
    );
}

const InformationModuleInfo = {
    name: "Informação",
    id: "info",
    icon: <InfoIcon />,
    description: "Módulo simples com texto e botão para prosseguir",
};

const getDefaultInformationStep = () => {
    return {
        title: "",
        description: "",
        stepType: "info",
    };
};

const validateInformationStep = (step) => {
    if (step.title === "") {
        return { valid: false, reason: "O título não pode ser vazio." };
    }

    return { valid: true };
};

export { InformationModule, InformationModuleInfo, getDefaultInformationStep, validateInformationStep };
