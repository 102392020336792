import Cookies from "js-cookie";
import { toast } from "react-toastify";
import config from "../config";

export const headers = () => {
	let authToken = Cookies.get("culturama-auth-token");
	if (authToken == null) {
		window.location.href = "/login";
		return;
	}

	let opts = {
		headers: { Authorization: `Bearer ${authToken}` },
	};

	let licenseId = Cookies.get("culturama-license-id");
	if (licenseId != null) {
		opts.headers["x-license-id"] = licenseId;
	}

	return opts;
};

export const errorHandler = (customHandler = null) => {
	return (err) => {
		let statusCode = err.response?.status;

		if (customHandler != null) {
			if (customHandler(statusCode, err.response?.data)) return;
		}

		switch (statusCode) {
			case 401:
				Cookies.remove("culturama-auth-token");
				Cookies.remove("culturama-license-id");
				window.location.href = "/login";
				break;
			case 413:
				toast.error(
					"Ocorreu um erro no seu pedido: Ficheiro muito grande (>3MB)"
				);
				break;
			default:
				if (config.DEBUG_MODE) console.log(err);
				toast.error("Oops! Ocorreu um erro inesperado");
				break;
		}
	};
};
