import {
	BrowserRouter,
	Route,
	Routes,
	Link as RouterLink,
	useSearchParams,
	useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Main from "./Main";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import colors from "./utils/colors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register";
import React from "react";
import ForgotPassword from "./pages/ForgotPassword";
import { LoadingProvider } from "./hooks/useWithLoading";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import "moment/locale/pt";

const LinkBehavior = React.forwardRef((props, ref) => {
	const { href, ...other } = props;
	// Map href (MUI) -> to (react-router)
	return <RouterLink ref={ref} to={href} {...other} />;
});

function App() {
	const theme = createTheme({
		components: {
			MuiLink: {
				defaultProps: {
					component: LinkBehavior,
				},
			},
		},
		palette: {
			primary: {
				main: colors.primary,
			},
		},
	});

	const LoggedInCallback = () => {
		const [searchParams] = useSearchParams();
		const navigate = useNavigate();

		useEffect(() => {
			let token = searchParams.get("token");
			if (token != null) {
				Cookies.set("culturama-auth-token", token);
			}

			navigate("/", { replace: true });
		}, []); // eslint-disable-line

		return null;
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<LocalizationProvider adapterLocale="pt" dateAdapter={AdapterMoment}>
				<LoadingProvider>
					<BrowserRouter>
						<Routes>
							<Route path="/login" element={<Login />} />
							<Route path="/register" element={<Register />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/logged-in" element={<LoggedInCallback />} />
							<Route path="/*" element={<Main />} />
						</Routes>
						<ToastContainer />
					</BrowserRouter>
				</LoadingProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;
