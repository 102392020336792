import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	Icon,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AreYouSureDialog from "../AreYouSureDialog";
import ReactQuill from "react-quill";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhotoIcon from "@mui/icons-material/Photo";
import { useRef } from "react";
import { Box } from "@mui/system";
import config from "../../config";

function ModuleBase(props) {
	const data = { ...props.data };

	const pictureRef = useRef();

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const setExpanded = (ev, ex) => {
		data.expanded = ex;
		props.updateData(data);
	};

	const setTitle = (ev) => {
		data.title = ev.target.value;
		props.updateData(data);
	};

	const setDescription = (value, oldValue, source) => {
		if (source === "user") {
			data.description = value;
			props.updateData(data);
		}
	};

	const setPicture = (ev) => {
		data.pictureFile = ev.target.files[0];
		props.updateData(data);
	};

	const removePicture = () => {
		data.pictureFile = null;
		pictureRef.current.value = null;
		data.picture = null;
		props.updateData(data);
	};

	return (
		<Accordion expanded={data.expanded ?? false} onChange={setExpanded}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Icon color="primary" sx={{ mr: 1 }}>
					{props.icon}
				</Icon>
				<Typography color="primary" fontWeight="bold" fontSize="large">
					{props.title}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.pictureFile != null ? (
					<Box maxHeight={200} maxWidth={356} sx={{ mb: 1 }}>
						<img
							alt=""
							style={{ objectFit: "cover", maxHeight: 200, width: "100%" }}
							src={URL.createObjectURL(data.pictureFile)}
						/>
					</Box>
				) : data.picture != null ? (
					<Box maxHeight={200} maxWidth={356} sx={{ mb: 1 }}>
						<img
							alt=""
							style={{ objectFit: "cover", maxHeight: 200, width: "100%" }}
							src={`${config.API_BASE_URL}/uploads/${data.picture}`}
						/>
					</Box>
				) : null}
				<Button
					variant="outlined"
					color="secondary"
					startIcon={<PhotoIcon />}
					component="label"
					sx={{ mr: 1, mb: 1 }}
				>
					Imagem
					<input
						type="file"
						accept="image/png, image/jpg, image/jpeg"
						ref={pictureRef}
						hidden
						onChange={setPicture}
					/>
				</Button>
				{data.pictureFile != null || data.picture != null ? (
					<Button
						variant="outlined"
						color="secondary"
						startIcon={<DeleteIcon />}
						onClick={removePicture}
						sx={{ mb: 1 }}
					>
						Remover Imagem
					</Button>
				) : null}

				<TextField
					label="Título"
					variant="outlined"
					fullWidth
					required
					sx={{ mb: 1, mt: 1 }}
					value={data.title}
					onChange={setTitle}
				/>
				<Typography fontWeight="light" fontSize="small">
					Descrição
				</Typography>
				<ReactQuill
					theme="snow"
					value={data.description}
					onChange={setDescription}
				/>
				<Divider sx={{ mb: 2 }} />
				{props.children}
				<Button
					color="secondary"
					sx={{ float: "right", m: 2 }}
					onClick={() => setDeleteDialogOpen(true)}
					endIcon={<DeleteIcon />}
				>
					Remover Módulo
				</Button>
				<AreYouSureDialog
					open={deleteDialogOpen}
					content="Tens a certeza que queres apagar este módulo?"
					onClose={() => setDeleteDialogOpen(false)}
					onConfirm={() => {
						props.onDelete();
						setDeleteDialogOpen(false);
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
}

export default ModuleBase;
