import { Container, Divider, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../config";
import { useWithLoading } from "../hooks/useWithLoading";
import { errorHandler, headers } from "../utils/axios-utils";
import moment from "moment";

function Organization(props) {
	const withLoading = useWithLoading();

	const [organization, setOrganization] = useState(null);

	useEffect(() => {
		withLoading(
			axios
				.get(`${config.API_BASE_URL}/organizations/mine`, headers())
				.then((res) => {
					setOrganization(res.data);
				})
				.catch(errorHandler())
		);
	}, []); // eslint-disable-line

	const getLicenseName = (type) => {
		switch (type) {
			case "monthly":
				return "Mensal";
			case "yearly":
				return "Anual";
			case "lifetime":
				return "Infinita";
			default:
				return "";
		}
	};

	const getLicenseExpiration = () => {
		if (props.license.type !== "lifetime") {
			return (
				<Typography color="secondary">
					Expira a{" "}
					<strong>
						{moment(props.license.expiryDate).format("DD-MM-yyyy")}
					</strong>
				</Typography>
			);
		}
	};

	if (organization == null) return null;

	return (
		<Container>
			<Paper sx={{ p: 4 }}>
				<Typography variant="h2" color="primary" fontWeight="bold">
					{organization.name}
				</Typography>
				<Divider />
				<Typography sx={{ mt: 1 }}>
					Juntou-se a{" "}
					<strong>
						{moment(organization.joinedDate).format("DD-MM-yyyy")}
					</strong>
				</Typography>
				<Typography sx={{ mt: 3 }}>
					Tipo de Licença:{" "}
					<strong>{getLicenseName(props.license.licenseType)}</strong>
				</Typography>
				{getLicenseExpiration()}
			</Paper>
		</Container>
	);
}

export default Organization;
