import React, { useState } from 'react'
import { Button, Container, Link, Paper, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import logo from "../img/logo.png";
import colors from '../utils/colors';
import axios from 'axios';
import config from '../config';
import { errorHandler } from '../utils/axios-utils';
import { useWithLoading } from '../hooks/useWithLoading';

function Register() {
    const withLoading = useWithLoading();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const register = () => {
        if (email === "" || password === "" || confirmPassword === "") {
            return toast.error("Deves preencher todos os campos.");
        }

        if (password !== confirmPassword) {
            return toast.error("As passwords devem coincidir.");
        }

        withLoading(axios.post(`${config.API_BASE_URL}/auth/register`, {
            email,
            password
        })
            .then(() => {
                toast.success("Conta criada com sucesso! Verifica o teu email.");
            })
            .catch(errorHandler((code) => {
                switch (code) {
                    case 400:
                        toast.error("Password muito curta (min. 5)");
                        return true;
                    case 409:
                        toast.error("Este email já está em uso.");
                        return true;
                    default:
                        return false;
                }
            })));
    };

    return (
        <Container sx={{ mt: 3 }}>
            <img alt="Culturama" src={logo} height={150} style={{ margin: "0 auto", display: "block" }} />
            <Paper sx={{ mt: 1, p: 3 }}>
                <Typography variant="h2" sx={{ fontSize: 30, textAlign: 'center', color: colors.primary }}>Criar conta</Typography>
                <TextField variant="outlined" name="email" type="email" label="Email" fullWidth sx={{ mt: 4 }} value={email} onChange={e => setEmail(e.target.value)}></TextField>
                <TextField variant="outlined" name="password" type="password" label="Password" fullWidth sx={{ mt: 2 }} value={password} onChange={e => setPassword(e.target.value)}></TextField>
                <TextField variant="outlined" name="confirm-password" type="password" label="Confirmar Password" fullWidth sx={{ mt: 2 }} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}></TextField>
                <Button variant="contained" sx={{ m: "0 auto", mt: 2, color: "white", display: "block" }} fullWidth onClick={register}>Criar conta</Button>
                <Typography sx={{ mt: 2 }}>Já tens uma conta? <Link href="/login">Entra aqui</Link></Typography>
            </Paper>
        </Container>
    )
}

export default Register;