import {
	Card,
	CardContent,
	CardMedia,
	Divider,
	Grid,
	Link,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "../styles/events.css";
import { useEffect } from "react";
import { useWithLoading } from "../hooks/useWithLoading";
import config from "../config";
import { errorHandler, headers } from "../utils/axios-utils";
import axios from "axios";
import moment from "moment";

const defaultEventBoxStyling = { height: 200, borderRadius: 5 };

function Events() {
	const withLoading = useWithLoading();

	const [events, setEvents] = useState([]);

	useEffect(() => {
		withLoading(
			axios
				.get(`${config.API_BASE_URL}/events/mine`, headers())
				.then((res) => setEvents(res.data))
				.catch(errorHandler())
		);
	}, []); // eslint-disable-line

	return (
		<Grid container spacing={3} sx={{ p: 5 }}>
			{events.map((event, index) => (
				<Grid key={index} item xs={12} sm={6} md={4} xl={3}>
					<EventButton event={event} />
				</Grid>
			))}
			<Grid item xs={12} sm={6} md={4} xl={3}>
				<CreateEventButton />
			</Grid>
		</Grid>
	);
}

function CreateEventButton() {
	return (
		<Link href="/create-event">
			<Box
				className="event-box"
				sx={{
					...defaultEventBoxStyling,
					p: 2,
					outlineStyle: "dashed",
					outlineWidth: 3,
					outlineColor: "lightgray",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<AddIcon sx={{ fontSize: 80, display: "block", color: "lightgray" }} />
			</Box>
		</Link>
	);
}

function EventButton({ event }) {
	return (
		<Link href={`/event/${event._id}`} sx={{ textDecoration: "none" }}>
			<Card
				className="event-box"
				sx={{
					...defaultEventBoxStyling,
					outlineStyle: "solid",
					outlineWidth: 1,
					outlineColor: "lightgray",
					boxShadow: "none",
					pb: 1,
				}}
			>
				{event.picture != null ? (
					<>
						<CardMedia
							sx={{ height: 100 }}
							image={`${config.API_BASE_URL}/uploads/${event.picture}`}
							title=""
						/>
						<Divider />
					</>
				) : null}
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{event.name}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						Localização: {event.location}
					</Typography>
					{event.permanent ?
						<Typography variant="body2" color="text.secondary">
							Evento Permanente
						</Typography> :
						<Typography variant="body2" color="text.secondary">
							Data e Hora: {moment(event.datetime).format("DD/MM/YYYY HH:mm")}
						</Typography>}
				</CardContent>
			</Card>
		</Link>
	);
}

export default Events;
