import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React from "react";

export default function AreYouSureDialog(props) {
	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Tens a certeza?</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Não</Button>
				<Button onClick={props.onConfirm} autoFocus>
					Sim
				</Button>
			</DialogActions>
		</Dialog>
	);
}
