import React from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ModuleBase from "./ModuleBase";
import { Box, Button, Checkbox, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function QuizzModule(props) {
	const data = { ...props.data };

	const setQuestion = (e) => {
		data.question = e.target.value;
		props.updateData(data);
	};

	const addAnswer = () => {
		data.answers.push("");
		props.updateData(data);
	};

	const removeAnswer = (index) => {
		data.answers.splice(index, 1);

		if (data.correctAnswerIndex === index) {
			data.correctAnswerIndex = 0;
		}

		props.updateData(data);
	};

	const updateAnswer = (index, value) => {
		data.answers[index] = value;
		props.updateData(data);
	};

	const setCorrectAnswerIndex = (index) => {
		data.correctAnswerIndex = index;
		props.updateData(data);
	};

	return (
		<ModuleBase
			title="Quizz"
			icon={QuizzModuleInfo.icon}
			onDelete={props.onDelete}
			updateData={props.updateData}
			data={data}
		>
			<TextField
				label="Pergunta"
				variant="outlined"
				fullWidth
				required
				value={data.question}
				onChange={setQuestion}
				autoComplete="off"
				sx={{ mb: 2 }}
			/>
			{data.answers.map((answer, index) => (
				<TextField
					key={index}
					sx={{ mb: 1 }}
					fullWidth
					label={`Resposta #${index + 1}`}
					value={answer}
					onChange={(e) => updateAnswer(index, e.target.value)}
					InputProps={{
						endAdornment: (
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<IconButton onClick={() => removeAnswer(index)}>
									<DeleteIcon />
								</IconButton>
								<Checkbox
									checked={data.correctAnswerIndex === index}
									onChange={() => setCorrectAnswerIndex(index)}
								/>
							</Box>
						),
					}}
				/>
			))}
			<Button onClick={addAnswer} color="secondary">
				Adicionar Resposta
			</Button>
		</ModuleBase>
	);
}

const QuizzModuleInfo = {
	name: "Quizz",
	id: "quizz",
	icon: <QuestionAnswerIcon />,
	description: "O utilizador terá que responder a uma pergunta para prosseguir",
};

const getDefaultQuizzStep = () => {
	return {
		title: "",
		description: "",
		stepType: "quizz",

		question: "",
		answers: [],
		correctAnswerIndex: 0,
	};
};

const validateQuizzStep = (step) => {
	if (step.title === "") {
		return { valid: false, reason: "O título não pode ser vazio." };
	}

	if (step.question === "") {
		return { valid: false, reason: "A pergunta não pode ser vazia." };
	}

	if (step.answers.length === 0) {
		return { valid: false, reason: "Deves adicionar pelo menos uma resposta." };
	}

	for (let answer of step.answers) {
		if (answer === "") {
			return { valid: false, reason: "As respostas não podem ser vazias." };
		}
	}

	return { valid: true };
};

export { QuizzModule, QuizzModuleInfo, getDefaultQuizzStep, validateQuizzStep };
